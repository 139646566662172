import { getLocaleFromUrl } from './windowUtils';

export const translateI18nField = (obj, targetKey) => {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            translateI18nField(obj[key], targetKey);
        }
        if (key === `${targetKey}_i18n`) {
            obj[targetKey] = obj[key][getLocaleFromUrl()] || obj[key]['en'];
        }
    }
};

export const translateNameI18n = (obj) => {
    translateI18nField(obj, 'name');
};

export const getTranslation = (obj, key) => {
    return obj[key][getLocaleFromUrl()];
};
